import React from "react";
import "../css/Testimonials.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  // Fetch testimonials data from translations
  const testimonials = t("testimonials.testimonials", { returnObjects: true });
  return (
    <section
      id="testimonials"
      className="testimonials-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <Helmet>
  <title>{t("seo.testimonials.title")}</title>
  <meta name="description" content={t("seo.testimonials.description")} />
  <meta property="og:title" content={t("seo.testimonials.title")} />
  <meta property="og:description" content={t("seo.testimonials.description")} />
  <meta property="og:image" content="https://images.businessnewsdaily.com/app/uploads/2022/04/04082415/Customer_Service_Getty_nortonrsx.jpg" />
  <html lang={i18n.language} />
</Helmet>
      <h2>{t("testimonials.title")}</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img
              src={testimonial.img}
              alt={testimonial.name}
              className="testimonial-img"
            />
            <h3>{testimonial.name}</h3>
            <p className="location">{testimonial.location}</p>
            <p className="review">"{testimonial.review}"</p>
            <p className="rating">{"⭐".repeat(testimonial.rating)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Testimonials;
