import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translations
import en from "./locales/en/translation.json";
import fr from "./locales/fr/translation.json";
import tr from "./locales/tr/translation.json";
import ku from "./locales/ku/translation.json";
import es from "./locales/es/translation.json";
import sq from "./locales/sq/translation.json";
import ar from "./locales/ar/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      tr: { translation: tr },
      ku: { translation: ku },
      es: { translation: es },
      sq: { translation: sq },
      ar: { translation: ar },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path", "htmlTag", "navigator"],
      lookupFromPathIndex: 0,
    },
  });

export default i18n;
