import React from "react";
import { useTranslation } from "react-i18next"; // Import translation hook
import {
  FaCar,
  FaCogs,
  FaRoad,
  FaWrench,
  FaTools,
  FaCarAlt,
  FaCarSide,
  FaCarCrash,
  FaCarBattery,
} from "react-icons/fa"; // Importing icons
import "../css/Proficiency.css";
import { Helmet } from "react-helmet";  // Import Helmet
// Icons mapping for the brands
const brandIcons = {
  Audi: <FaCar />,
  BMW: <FaCogs />,
  "Mercedes-Benz": <FaRoad />,
  Opel: <FaWrench />,
  Porsche: <FaTools />,
  Volkswagen: <FaCarAlt />,
  Toyota: <FaCarSide />,
  Mazda: <FaCarCrash />,
  Lexus: <FaCarBattery />,
};
const Proficiency = () => {
  const { t, i18n } = useTranslation(); // Using the translation hook

  // Fetch proficiency data from translations
  const proficiencyData = t("proficiency", { returnObjects: true });

  if (!proficiencyData) {
    return <p>Language data not available.</p>;
  }
  const { title, description, group, brands } = proficiencyData;
  return (
    <section
      className="proficiency-section"
      dir={i18n.language === "ar" ? "rtl" : "ltr"} // Adjust text direction for Arabic
    >
      {/* SEO meta tags for Proficiency */}
      <Helmet>
        <title>{t("seo.proficiency.title")}</title>
        <meta name="description" content={t("seo.proficiency.description")} />
        
        <meta property="og:title" content={t("seo.proficiency.title")} />
        <meta property="og:description" content={t("seo.proficiency.description")} />
        <meta property="og:image" content="https://images3.alphacoders.com/771/771190.jpg"  />
        <html lang={i18n.language} />
      </Helmet>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="brands-grid">
        {brands.map((brand, index) => (
          <div key={index} className="brand-card">
            <div className="brand-icon">
              {brandIcons[brand.name] || <FaCar />}
            </div>
            <img
              src={brand.logo || ""}
              alt={`${brand.name} Logo`}
              className="brand-logo"
            />
            <h3>{brand.name}</h3>
            <p className="brand-founded">{brand.founded}</p>
            <p className="brand-group">
              {group}: <span>{brand.group}</span>
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
export default Proficiency;
