import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import WhoWeAre from "./components/WhoWeAre";
import ServicesOverview from "./components/ServicesOverview";
import RepairProcess from "./components/RepairProcess";
import Statistics from "./components/Statistics";
import OurProducts from "./components/OurProducts";
import Proficiency from "./components/Proficiency";
import FAQ from "./components/FAQ";
import RequestCallback from "./components/RequestCallback";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import SchemaMarkup from "./components/SchemaMarkup";

const LanguageWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
      document.documentElement.dir = lang === "ar" || lang === "ku" ? "rtl" : "ltr";
    }
  }, [lang, i18n]);

  return children;
};

const ScrollToSection = ({ sectionId }) => {
  const location = useLocation();

  useEffect(() => {
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, sectionId]);

  return null;
};

const App = () => {
  return (
    <Router>
      <Helmet>
        {/* Global hreflang tags */}
        <link rel="alternate" hreflang="en" href="https://www.swordgarage.com/" />
        <link rel="alternate" hreflang="tr" href="https://www.swordgarage.com/tr/" />
        <link rel="alternate" hreflang="ar" href="https://www.swordgarage.com/ar/" />
        <link rel="alternate" hreflang="fr" href="https://www.swordgarage.com/fr/" />
        <link rel="alternate" hreflang="es" href="https://www.swordgarage.com/es/" />
        <link rel="alternate" hreflang="x-default" href="https://www.swordgarage.com/" />
      </Helmet>
    <SchemaMarkup /> {/* Add SchemaMarkup here */}
      <Routes>
        {/* Default Redirect */}
        <Route path="/" element={<Navigate to="/en" />} />

        {/* Language-Specific Routes */}
        <Route
          path="/:lang"
          element={
            <LanguageWrapper>
              <div className="App">
                <Header />
                <HeroSection />
                <WhoWeAre />
                <ServicesOverview />
                <RepairProcess />
                <Statistics />
                <OurProducts />
                <Proficiency />
                <FAQ />
                <RequestCallback />
                <Testimonials />
                <Footer />
              </div>
            </LanguageWrapper>
          }
        />

        {/* Section-Specific Routes */}
        <Route
          path="/:lang/products"
          element={
            <LanguageWrapper>
              <ScrollToSection sectionId="products" />
              <div className="App">
                <Header />
                <HeroSection />
                <WhoWeAre />
                <ServicesOverview />
                <RepairProcess />
                <Statistics />
                <OurProducts />
                <Proficiency />
                <FAQ />
                <RequestCallback />
                <Testimonials />
                <Footer />
              </div>
            </LanguageWrapper>
          }
        />
        <Route
          path="/:lang/services"
          element={
            <LanguageWrapper>
              <ScrollToSection sectionId="services" />
              <div className="App">
                <Header />
                <HeroSection />
                <WhoWeAre />
                <ServicesOverview />
                <RepairProcess />
                <Statistics />
                <OurProducts />
                <Proficiency />
                <FAQ />
                <RequestCallback />
                <Testimonials />
                <Footer />
              </div>
            </LanguageWrapper>
          }
        />
        <Route
  path="/:lang/testimonials"
  element={
    <LanguageWrapper>
      <ScrollToSection sectionId="testimonials" />
      <div className="App">
        <Header />
        <HeroSection />
        <WhoWeAre />
        <ServicesOverview />
        <RepairProcess />
        <Statistics />
        <OurProducts />
        <Proficiency />
        <FAQ />
        <RequestCallback />
        <Testimonials />
        <Footer />
      </div>
    </LanguageWrapper>
  }
/>
<Route
  path="/:lang/faq"
  element={
    <LanguageWrapper>
      <ScrollToSection sectionId="faq" />
      <div className="App">
        <Header />
        <HeroSection />
        <WhoWeAre />
        <ServicesOverview />
        <RepairProcess />
        <Statistics />
        <OurProducts />
        <Proficiency />
        <FAQ />
        <RequestCallback />
        <Testimonials />
        <Footer />
      </div>
    </LanguageWrapper>
  }
/>


        {/* Add similar routes for other sections */}
      </Routes>
    </Router>
  );
};

export default App;
