import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import translation hook
import "../css/HeroSection.css";
import { Helmet } from "react-helmet";  // Import Helmet

// Define slides with static images
const slides = [
  {
    img: "https://4kwallpapers.com/images/wallpapers/bmw-m3-angel-eyes-black-background-5k-3840x2160-896.jpg",
  },
  {
    img: "https://i.pinimg.com/736x/4a/a3/5a/4aa35a2ddfbaec6175879c9ccd6afd79.jpg",
  },
  {
    img: "https://wallpapercave.com/wp/wp2170287.jpg",
  },
];

const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t,i18n } = useTranslation(); // Access translations
  // Translate slide content dynamically
  const translatedSlides = t("hero.slides", { returnObjects: true });
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Slide changes every 5 seconds

    return () => clearInterval(slideInterval); // Cleanup interval on unmount
  }, []);
  return (
    <section
      className="hero-section"
      style={{
        backgroundImage: `url(${slides[currentSlide].img})`,
      }}
    >
       {/* SEO meta tags for HeroSection */}
       <Helmet>
        <title>{t("seo.hero.title")}</title>
        <meta name="description" content={t("seo.hero.description")} />
        
        <meta property="og:title" content={t("seo.hero.title")} />
        <meta property="og:description" content={t("seo.hero.description")} />
        <meta property="og:image" content={`url(${slides[currentSlide].img})`} />
        <html lang={i18n.language} />
      </Helmet>
      <div className="hero-overlay">
        <div className="hero-content">
          <h1>{translatedSlides[currentSlide].title}</h1>
          <p>{translatedSlides[currentSlide].description}</p>
          <button className="read-more">{t("hero.readMore")}</button>
        </div>
      </div>
      <div className="hero-controls">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`control-dot ${currentSlide === index ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </section>
  );
};
export default HeroSection;
