import React from "react";
import { FaTools, FaCar, FaUsers, FaSmile } from "react-icons/fa"; // Importing icons
import { useTranslation, Trans } from "react-i18next";
import "../css/Statistics.css";
import { Helmet } from "react-helmet";

const Statistics = () => {
  const { t,i18n } = useTranslation();
  const stats = [
    { value: "19", label: t("statistics.stats.experience.label"), icon: <FaTools /> },
    { value: "9.5k+", label: t("statistics.stats.vehicles.label"), icon: <FaCar /> },
    { value: "150", label: t("statistics.stats.workers.label"), icon: <FaUsers /> },
    { value: "100%", label: t("statistics.stats.customers.label"), icon: <FaSmile /> },
  ];
  return (
    <section className="statistics-section">
      <Helmet>
        <title>{t("seo.statistics.title")}</title>
        <meta name="description" content={t("seo.statistics.description")} />
        <meta property="og:title" content={t("seo.statistics.title")} />
        <meta property="og:description" content={t("seo.statistics.description")} />
        <meta property="og:image" content="https://i.ibb.co/hF5wRdy/14fa9ba2-3a83-4283-a166-fa88b29327e9.jpg" />
        <html lang={i18n.language} />
      </Helmet>
      <div className="container">
        <div className="image-area">
          <img
            src="https://i.ibb.co/hF5wRdy/14fa9ba2-3a83-4283-a166-fa88b29327e9.jpg" // Replace with actual image URL
            alt={t("statistics.subTitle")}
          />
        </div>
        <div className="content-area">
          {/* <h3>{t("statistics.title")}</h3> */}
          <h2>{t("statistics.subTitle")}</h2>
          <p>
            <Trans
              i18nKey="statistics.description"
              values={{ garageName: "Sword Garage" }}
              components={{ strong: <strong /> }}
            />
          </p>
          <div className="stats-grid">
            {stats.map((stat, index) => (
              <div key={index} className="stat-item">
                <div className="stat-icon">{stat.icon}</div>
                <h4>{stat.value}</h4>
                <p>{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Statistics;
