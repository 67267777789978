import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const SchemaMarkup = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  // Multilingual structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "AutoRepair",
    "name": {
      en: "Sword Garage",
      ar: "كراج السيف",
      tr: "Kılıç Garaj",
      fr: "Garage Sword",
      es: "Espada Garaje",
      sq: "Garaja Shpatë",
      ku: "Garaja Şûr",
    }[language],
    "url": `https://www.swordgarage.com/${language !== "en" ? language : ""}`,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "54 Penn Dr, North York, ON",
      "addressLocality": "Toronto",
      "addressRegion": "Ontario",
      "postalCode": "M9L 2A9",
      "addressCountry": "Canada",
    },
    "telephone": "+1-123-456-7890",
    "description": {
      en: "Premium Auto Repair Services at Sword Garage.",
      ar: "خدمات إصلاح السيارات المتميزة في كراج السيف.",
      tr: "Türk tamirci hizmetleri Toronto'da. Güvenilir araba tamiri, fren ve motor onarımı.",
      fr: "Services de réparation automobile premium chez Garage Sword.",
      es: "Servicios de reparación de automóviles premium en Espada Garaje.",
      sq: "Shërbime premium për riparimin e automjeteve në Garaja Shpatë.",
      ku: "Xizmeta taybetî ya çakkirina otoyên di Garaja Şûr de.",
    }[language],
    "image": "https://www.swordgarage.com/logo.png",
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
  );
};
export default SchemaMarkup;
