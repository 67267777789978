import React from "react";
import { useTranslation } from "react-i18next"; // Import the translation hook
import "../css/WhoWeAre.css";
import { Helmet } from "react-helmet";

const WhoWeAre = () => {
  const { t,i18n } = useTranslation(); // Access translations
  return (
    <section id="who-we-are" className="who-we-are-section">
      {/* SEO meta tags */}
      <Helmet>
        <title>{t("seo.whoWeAre.title")}</title>
        <meta name="description" content={t("seo.whoWeAre.description")} />
        {/* Optional: social share tags */}
        <meta property="og:title" content={t("seo.whoWeAre.title")} />
        <meta property="og:description" content={t("seo.whoWeAre.description")} />
        <meta property="og:image" content="https://i.ibb.co/7kw6T1m/40.webp" />
        <html lang={i18n.language} />
      </Helmet>
      <div className="container">
        <div className="content">
          <h2>{t("whoWeAre.title")}</h2>
          <p>
            {t("whoWeAre.paragraph1", {
              brand: <strong>Sword Garage</strong>, // Dynamic brand name
            })}
          </p>
          <p>{t("whoWeAre.paragraph2")}</p>
        </div>
        <div className="team-photo">
          <img
            src="https://i.ibb.co/7kw6T1m/40.webp" // Replace with an actual image URL
            alt={t("whoWeAre.teamPhotoAlt")}
          />
        </div>
      </div>
    </section>
  );
};
export default WhoWeAre;
