import React from "react";
import "../css/Footer.css";
import { Helmet } from "react-helmet";  // Import Helmet
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      {/* Global SEO metadata (if needed) */}
      <Helmet>
  <title>{t("seo.footer.title", { defaultValue: "Premium Auto Repair Services | Sword Garage" })}</title>
  <meta name="keywords" content={t("seo.home.keywords")} />
  <meta name="keywords" content="Türk tamirci, Türk mekanik servis, Kılıç Garaj, araba tamiri" />
  <meta name="description" content={t("seo.footer.description", { defaultValue: "Stay connected with Sword Garage for premium auto repair services. Contact us today!" })} />
  <meta property="og:title" content={t("seo.footer.title", { defaultValue: "Premium Auto Repair Services | Sword Garage" })} />
  <meta property="og:description" content={t("seo.footer.description", { defaultValue: "Stay connected with Sword Garage for premium auto repair services. Contact us today!" })} />
  <html lang={i18n.language} />
</Helmet>

      <div className="container">
        <div className="footer-left">
          <p className="footer-logo">{t("footer.logo")}</p>
          <p>{t("footer.copyright")}</p>
          <p>
            <FaPhone className="icon" /> {" "}
            <a href={`tel:${t("footer.phone")}`}>{t("footer.phone")}</a>
          </p>
          <p>
            <FaEnvelope className="icon" /> {" "}
            <a href={`mailto:${t("footer.email")}`}>{t("footer.email")}</a>
          </p>
          <p>
            <FaMapMarkerAlt className="icon" /> {" "}
            <a
              href={t("footer.mapLink")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.address")}
            </a>
          </p>
        </div>
        <div className="footer-right">
          <div className="map-container">
            <iframe
              className="map"
              src={t("footer.mapEmbedLink")}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
            {/* Social Icons */}
            <div className="footer-socials">
          <a
            href={t("footer.socials.facebook")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaFacebookF />
          </a>
          <a
            href={t("footer.socials.twitter")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaTwitter />
          </a>
          <a
            href={t("footer.socials.instagram")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaInstagram />
          </a>
          <a
            href={t("footer.socials.linkedin")}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
