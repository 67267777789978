import React, { useState } from "react";
import { FaTools, FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../css/Header.css";
import { Helmet } from "react-helmet";  // Import Helmet
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const htmlTag = document.documentElement;
    // Set text direction and language attribute
    if (lng === "ar" || lng === "ku") {
      htmlTag.setAttribute("dir", "rtl");
    } else {
      htmlTag.setAttribute("dir", "ltr");
    }
    htmlTag.setAttribute("lang", lng);
    // Navigate to the language-specific home route
    navigate(`/${lng}`);
  };
  return (
    <header className="header">
       {/* Global SEO metadata (if desired) */}
       <Helmet>
        {/* <title>{t("seo.home.title")}</title> */}
        <title>{t("seo.home.title") || "Türk Tamirci Toronto - Sword Garage"}</title>
        {/* <meta name="keywords" content={t("seo.home.keywords")} />
        <meta name="keywords" content="Türk tamirci, Türk mekanik servis, Kılıç Garaj, araba tamiri" /> */}
          <meta name="keywords" content="Türk tamirci, tamirci Toronto, araba tamiri Toronto, Kılıç Garaj, Türk oto tamir servisi" />
        {/* <meta name="description" content={t("seo.home.description")} /> */}
        <meta name="description" content={t("seo.home.description") || "Türk tamirci hizmetleri Toronto'da. Güvenilir araba tamiri ve onarım."} />
        {/* <meta property="og:title" content={t("seo.home.title")} /> */}
        <meta property="og:title" content="Türk Tamirci Toronto - Sword Garage" />
        {/* <meta property="og:description" content={t("seo.home.description")} /> */}
        <meta property="og:description" content="Türk tamirci hizmetleri Toronto'da. Güvenilir araba tamiri ve onarım." />
        {/* <meta property="og:image" content="https://i.ibb.co/prHSzjk/1.png" /> */}
        <meta property="og:image" content="https://i.ibb.co/prHSzjk/1.png" />
        <html lang={i18n.language} />
        {/* hreflang tags */}
        <link rel="alternate" hreflang="en" href="https://www.swordgarage.com/" />
        <link rel="alternate" hreflang="tr" href="https://www.swordgarage.com/tr/" />
        <link rel="alternate" hreflang="ar" href="https://www.swordgarage.com/ar/" />
        <link rel="alternate" hreflang="fr" href="https://www.swordgarage.com/fr/" />
        <link rel="alternate" hreflang="es" href="https://www.swordgarage.com/es/" />
        <link rel="alternate" hreflang="x-default" href="https://www.swordgarage.com/" />
      </Helmet>

  

       <div className="logo">
          <img src="https://i.ibb.co/prHSzjk/1.png" alt="Logo" className="logo-image" id="testlogo" />
          {t("header.title")}
        </div>
      <div className="container">
        {/* Hamburger menu for mobile */}
        <button className="menu-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        {/* Navigation links */}
        <nav className={`nav ${isMobileMenuOpen ? "nav-open" : ""}`}>
        <a href={`/${i18n.language}/services`} onClick={() => setIsMobileMenuOpen(false)}>
    {t("header.services")}
  </a>
  <a href={`/${i18n.language}/products`} onClick={() => setIsMobileMenuOpen(false)}>
    {t("header.products")}
  </a>
  <a href={`/${i18n.language}/faq`} onClick={() => setIsMobileMenuOpen(false)}>
    {t("header.faq")}
  </a>
  <a href={`/${i18n.language}/testimonials`} onClick={() => setIsMobileMenuOpen(false)}>
    {t("header.testimonials")}
  </a>
          <a
            className="quote-button"
            href="#callback"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            {t("header.getQuote")}
          </a>

          {/* Language Selector with Flags */}
          <div className="language-switcher">
            <img
              src="https://flagcdn.com/w320/us.png"
              alt="English"
              onClick={() => changeLanguage("en")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/fr.png"
              alt="French"
              onClick={() => changeLanguage("fr")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/tr.png"
              alt="Turkish"
              onClick={() => changeLanguage("tr")}
              className="language-flag"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/640px-Flag_of_Kurdistan.svg.png"
              alt="Kurdish"
              onClick={() => changeLanguage("ku")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/es.png"
              alt="Spanish"
              onClick={() => changeLanguage("es")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/al.png"
              alt="Albanian"
              onClick={() => changeLanguage("sq")}
              className="language-flag"
            />
            <img
              src="https://flagcdn.com/w320/iq.png"
              alt="Arabic"
              onClick={() => changeLanguage("ar")}
              className="language-flag"
            />
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;
