import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import { Helmet } from "react-helmet";  // Import Helmet
import "../css/Request.css";

const RequestCallback = () => {
  const { t, i18n } = useTranslation();
  const formRef = useRef(null);
  const isRTL = i18n.language === "ar";
  // This function is called when the user submits the form
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_oxe34mo",    // Your EmailJS Service ID
        "template_iwj76j2",   // Your EmailJS Template ID
        formRef.current,      // The form reference
        "Stiu1FZwkAYqBsTdW"   // Your EmailJS Public/User Key
      )
      .then(
        (result) => {
          console.log("Email sent:", result.text);
          // Use toastify for success message
          toast.success("Email sent successfully!");
          // Optionally reset the form
          e.target.reset();
        },
        (error) => {
          console.error("Error sending email:", error.text);
          // Use toastify for error message
          toast.error("Error sending email. Please try again later.");
        }
      );
  };
  return (
    <section
      id="callback"
      className="callback-section"
      dir={isRTL ? "rtl" : "ltr"}
    >
      {/* SEO meta tags for Request Callback */}
      <Helmet>
        <title>{t("seo.callback.title")}</title>
        <meta name="description" content={t("seo.callback.description")} />
        <meta property="og:title" content={t("seo.callback.title")} />
        <meta property="og:description" content={t("seo.callback.description")} />
        <meta property="og:image" content="https://i.ibb.co/jW8DK1m/req.webp" />
        <html lang={i18n.language} />
      </Helmet>
      <div className="callback-container">
        {/* Left-Side Image */}
        <div className="callback-image">
          <img
            src="https://i.ibb.co/jW8DK1m/req.webp"
            alt={t("callback.imageAlt")}
          />
        </div>
        {/* Right-Side Text and Form */}
        <div className="callback-content">
          <h2>{t("callback.title")}</h2>
          <p className="callback-description">{t("callback.description")}</p>
          {/* Form with ref to capture the form DOM */}
          <form className="callback-form" ref={formRef} onSubmit={sendEmail}>
            <input
              type="text"
              name="user_name"
              placeholder={t("callback.form.name")}
              required
            />
            <input
              type="text"
              name="user_phone"
              placeholder={t("callback.form.phone")}
              required
            />
            <input
              type="email"
              name="user_email"
              placeholder={t("callback.form.email")}
              required
            />
            {/* Customer message textarea */}
            <textarea
              name="user_message"
              placeholder="What would you like to request?"
              rows="5"
              required
            />
            <button type="submit">
              {t("callback.form.submit")}
            </button>
          </form>
        </div>
      </div>
      {/* Toastify container to show the toasts */}
      <ToastContainer 
        position="top-right" 
        autoClose={4000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={isRTL} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
    </section>
  );
};
export default RequestCallback;
