import React, { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa"; // Importing icons
import { useTranslation } from "react-i18next"; // Translation hook
import "../css/OurProducts.css";
import { Helmet } from "react-helmet";  // Import Helmet

const OurProducts = () => {
  const { t,i18n } = useTranslation();
  const [liked, setLiked] = useState(Array(12).fill(false)); // State to track likes for each product
  const [likeCounts, setLikeCounts] = useState([250, 180, 320, 400, 150, 275, 90, 120, 200, 110, 95, 130]);
  const toggleLike = (index) => {
    const newLiked = [...liked];
    const newLikeCounts = [...likeCounts];
    if (newLiked[index]) {
      newLikeCounts[index] -= 1; // Decrease count if unliked
    } else {
      newLikeCounts[index] += 1; // Increase count if liked
    }
    newLiked[index] = !newLiked[index]; // Toggle like state
    setLiked(newLiked);
    setLikeCounts(newLikeCounts);
  };
  const products = [
    {
      name: t("ourProducts.products.0.name"),
   
      description: t("ourProducts.products.0.description"),
      img: "https://liquimoly.cloudimg.io/v7/https://www.liqui-moly.com/media/catalog/product/cache/7c4066dd86a6b9217681a7aaaa67e822/2/3/2331_8e3f.png?w=200&h=200&func=bound&sharp=1&org_if_sml=1&force_format=webp%252Coriginal",
    },
    {
      name: t("ourProducts.products.1.name"),
    
      description: t("ourProducts.products.1.description"),
      img: "https://www.pgfilters.com/wp-content/uploads/2023/02/Bypass-valve-oil-filter-tip.jpg",
    },
    {
      name: t("ourProducts.products.2.name"),
      
      description: t("ourProducts.products.2.description"),
      img: "https://m.media-amazon.com/images/I/61MVfBRd6sL._AC_UF1000,1000_QL80_.jpg",
    },
    {
      name: t("ourProducts.products.3.name"),
 
      description: t("ourProducts.products.3.description"),
      img: "https://cdn11.bigcommerce.com/s-svnjf7qbac/images/stencil/1280x1280/products/1561/8935/AnyConv.com__9947LR_800x600__89712.1611936790.jpg?c=2",
    },
    {
      name: t("ourProducts.products.4.name"),
   
      description: t("ourProducts.products.4.description"),
      img: "https://www.fram.com/media/wysiwyg/products/FRAM-Web-AitFilter-Group_recolored.png",
    },
    {
      name: t("ourProducts.products.5.name"),
     
      description: t("ourProducts.products.5.description"),
      img: "https://steeda.ca/cdn/shop/products/2238_l.jpg?v=1490174706",
    },
    {
      name: t("ourProducts.products.6.name"), // Tires
      description: t("ourProducts.products.6.description"),
      img: "https://hips.hearstapps.com/hmg-prod/images/pile-of-tires-on-white-background-royalty-free-image-672151801-1561751929.jpg",
    },
    {
      name: t("ourProducts.products.7.name"), // Cabin Filters
      description: t("ourProducts.products.7.description"),
      img: "https://thumbs.dreamstime.com/b/new-car-cabin-air-cabon-filter-white-background-isolated-maintenance-service-280315908.jpg",
    },
    {
      name: t("ourProducts.products.8.name"), // Wipers
      description: t("ourProducts.products.8.description"),
      img: "https://m.media-amazon.com/images/I/616l09bM3VS.jpg",
    },
    {
      name: t("ourProducts.products.9.name"), // LED Lights
      description: t("ourProducts.products.9.description"),
      img: "https://c8.alamy.com/comp/2FX9YRC/led-car-lamp-isolated-white-background-led-car-lights-on-a-white-background-with-clipping-path-light-bulbs-for-car-lamps-2FX9YRC.jpg",
    },
    {
      name: t("ourProducts.products.10.name"), // Coolant
      description: t("ourProducts.products.10.description"),
      img: "https://partsource.ca/cdn/shop/files/Untitleddesign_ed6bf641-8037-41eb-907b-784940531908_700x700.png?v=1687878568",
    },
    {
      name: t("ourProducts.products.11.name"), // Windshield Washer Fluid
      description: t("ourProducts.products.11.description"),
      img: "https://partsource.ca/cdn/shop/products/0294199_1_720x720.jpg?v=1575619666",
    },
  ];
  return (
    <section id="products" className="products-section">
       {/* SEO meta tags for OurProducts */}
       <Helmet>
        <title>{t("seo.ourProducts.title")}</title>
        <meta name="description" content={t("seo.ourProducts.description")} />
        <meta property="og:title" content={t("seo.ourProducts.title")} />
        <meta property="og:description" content={t("seo.ourProducts.description")} />
        <meta property="og:image" content="https://liquimoly.cloudimg.io/v7/https://www.liqui-moly.com/media/catalog/product/cache/7c4066dd86a6b9217681a7aaaa67e822/2/3/2331_8e3f.png?w=200&h=200&func=bound&sharp=1&org_if_sml=1&force_format=webp%252Coriginal" />
        <html lang={i18n.language} />
      </Helmet>
      <h2>{t("ourProducts.title")}</h2>
      <div className="product-grid">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.img} alt={product.name} />
            <h3>{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price">{product.price}</p>
            <div className="actions">
              <button
                className="like-button"
                onClick={() => toggleLike(index)}
                aria-label={t("ourProducts.actions.like")}
              >
                {liked[index] ? <FaHeart className="liked" /> : <FaRegHeart />}
              </button>
              <span className="like-count">
                {likeCounts[index]} {t("ourProducts.actions.likes")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default OurProducts;
